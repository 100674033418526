<template>
  <div class="order-list">
    <Table :columns="ordersColumns" :data="ordersData" border :max-height="tableMaxHeight">
    </Table>

     <div
      style="margin: 20px 12px;overflow: hidden;position:fixed;bottom: 10px;right: 10px;width: 100%;"
    >
      <div style="display:flex;flex-direction:row-reverse;">
        <Page
          :total="totalCount"
          :current="currentPage"
          @on-change="changePage"
          :page-size="pageSize"
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinDevice } from '@/util/mixin';
import api from '@/api';

export default {
  mixins:[mixinDevice],
  data(){
    return {
      totalCount: 0,
      currentPage: 1,
      pageSize: 30,
      ordersColumns:[
       {
          title: "订单号",
          key: "order_no",
        },
        {
          title: '创建时间',
          key: 'create_time',
          default: '---'
        },
        {
          title: '激活后有效时间',
          key: 'effect_time',
          default: '---'
        }
      ],
      ordersData:[]
    }
  },
  methods:{
    changePage(value){
      this.currentPage = value;
      this.loadData();
    },
    loadData(){
      api.orderList(this.pageSize,this.currentPage).then(res=>{
        this.$log.debug(res);
        if(res.orders && res.orders.length > 0){
          this.ordersData = res.orders.map(item=>{
            var newItem = item;
            if(!newItem.create_time || newItem.create_time === ''){
              newItem.create_time = '/'
            }
            if(!newItem.effect_time || newItem.effect_time === ''){
              newItem.effect_time = '/'
            }
            return newItem;
          })
        }
        this.totalCount = res.total_count;
      }).catch(err=>{
        this.$log.debug(err);
      });
    }
  },
  computed:{
     tableMaxHeight(){
      return this.isMobile() ? 540 : 700;
    }
  },
  mounted(){
    this.loadData();
  }
}
</script>

<style lang="less" scoped>
  .order-list{
    width: 100%;
  }
</style>

