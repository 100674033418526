import { DEVICE_TYPE } from './device'
import Store from '../store';


const mixinDevice = {
    data(){
        return {
            device: null,
        }
    },
    methods: {
        isMobile(){
        //   console.log('isMobile: ',this.device === DEVICE_TYPE.MOBILE,"this device: ",this.device);
          return this.device === DEVICE_TYPE.MOBILE
        },
        isDesktop(){
            return this.device === DEVICE_TYPE.DESKTOP
        },
        isTablet(){
            return this.device === DEVICE_TYPE.TABLET
        }
    },
    mounted(){
    //   console.log("device: ",Store.state.deviceType);
      this.device = Store.state.deviceType;
    }
}

export { mixinDevice }